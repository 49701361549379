import React, { useEffect, useState } from 'react'
import Layout from '../../components/common/pageLayout'
import { toast } from 'react-toastify'
import ListState from '../../components/LedgerAndCashbook/paymentEntryList'
import constant from '../../constant'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { v3_ledger_entry_list } from '../../actions/v3/ledgerAndCashbook'
import { validateFunctionalForm } from '../../utils'
const List = (props) => {

    const location = useLocation();
    const { page } = location.state || {};
    const [data, setData] = useState(null)
    const [currentPage, setCurrentPage] = useState(page ? page : 1)
    const [limit, setLimit] = useState(10)
    const [entity, setEntity] = useState('')


    useEffect(() => {
        if (entity) {
            v3_ledger_entry_list(entity, currentPage, limit).then((res) => {
                if (res.status) {
                    setData(res.result);
                    props.history.push(constant.Authedcomponent.paymentList.url, {
                        page: currentPage
                    });
                }
                else {
                    toast.error(res.error)
                }
            })
        }
        else {
            // toast.error('Please Select Entity')
        }
    }, [currentPage, limit, entity])


    return (
        <Layout
            title={'Payment Entry List'}
        >
            <SearchOptions
                setData={setData}
                onSubmit={setEntity}
            />
            {data && <ListState
                entity={entity}
                limit={limit}
                data={data}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />}
        </Layout>
    )
}


const SearchOptions = (props) => {
    const [state, setState] = useState({
        entity: { name: 'entity', value: '', options: ['Party', 'Transporter'], isRequired: false, error: '' },
    });


    const handleChange = (e) => {
        const { name, value } = e.target;
        debugger
        setState(prevState => ({
            ...prevState,
            [name]: { ...prevState[name], value }
        }));
    };

    const handleSearch = () => {
        if (validateFunctionalForm(state, setState)) {
            props.onSubmit(state.entity.value);
        }
    };

    const handleReset = () => {
        setState({
            entity: { name: 'entity', value: '', options: ['Party', 'Transporter'], isRequired: false, error: '' },
        });
        props.setData(null);
    };

    return (
        <React.Fragment>
            <form className="row gy-12 gx-12 align-items-center">
                <div className={"col-sm-4 mb-2"}>
                    <label htmlFor="companySelect">Entity</label>
                    <div className="input-group">
                        <select
                            id="companySelect"
                            name="entity"
                            className={state.entity.error && !state.entity.value ? "form-select is-invalid" : "form-select"}
                            value={state.entity.value}
                            onChange={handleChange}
                        >
                            <option value="">Select Entity</option>
                            {state.entity.options.map(option => (
                                <option key={option} value={option}>{option}</option>
                            ))}
                        </select>
                    </div>
                </div>




                {/* Buttons */}
                <div className="col-sm-4 mb-2 d-flex align-items-center" style={{ top: '14px' }}>
                    <button
                        type="button"
                        className="btn btn-success waves-effect btn-label waves-light me-2"
                        onClick={handleSearch}
                    >
                        <i className="bx bx-search label-icon" /> Search
                    </button>
                    <button
                        type="button"
                        className="btn btn-outline-danger me-2"
                        onClick={handleReset}
                    >
                        <i className="bx bx-reset label-icon" />
                        Reset
                    </button>
                </div>
            </form>




        </React.Fragment>

    );
};
export default List