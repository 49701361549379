import axios from 'axios'
import { getAuthHeader } from '../../utils';

export const v2_stock_graph_data = async (periodStartMonth, periodEndMonth) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/stock/graph/${periodStartMonth}/${periodEndMonth}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}


export const v2_stock_available = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/stock`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

