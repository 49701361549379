import React, { useState, useEffect } from "react";
import Modal from "react-modal"; // Import React Modal
import { v2_stock_available } from "../../actions/v2/stock";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

// Ensure modal is attached to the app element
Modal.setAppElement('#root');

const StockModal = () => {
    const [stockData, setStockData] = useState([]); // Store stock data
    const [filteredStockData, setFilteredStockData] = useState([]); // Store filtered stock data based on search
    const [isOpen, setIsOpen] = useState(false);  // Modal state
    const [searchQuery, setSearchQuery] = useState(""); // Search query state

    // Open and close modal functions
    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);

    // Fetch stock data on component mount
    useEffect(() => {
        v2_stock_available()
            .then((res) => {
                setStockData(res.result);
                setFilteredStockData(res.result); // Initially, show all stock data
            })
            .catch((err) => console.error(err));
    }, []);

    // Handle search query change
    const handleSearchChange = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchQuery(query);

        // Filter stock data based on the search query
        const filteredData = stockData.filter(item =>
            item.PRODUCT_NAME.toLowerCase().includes(query) ||
            item.SUB_PRODUCT_NAME.toLowerCase().includes(query)
        );
        setFilteredStockData(filteredData);
    };

    // Export stock data to Excel
    const handleExport = () => {
        // Create a worksheet from filteredStockData
        const ws = XLSX.utils.json_to_sheet(filteredStockData.map((item) => ({
            'Product Name': item.PRODUCT_NAME,
            'Sub Product Name': item.SUB_PRODUCT_NAME,
            'Available Stock': item.AVAILABLE_STOCK + ' Kg',
        })));
        
        // Create a workbook and append the worksheet to it
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Stock Report");
        
        // Write the workbook to a file and trigger download
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const file = new Blob([excelBuffer], { bookType: "xlsx", type: "application/octet-stream" });
        saveAs(file, "stock_report.xlsx");
    };

    return (
        <React.Fragment>
            {/* Button to trigger modal */}
            <button
                type="button"
                className="btn btn-success ms-2"
                style={{
                    fontSize: 16,
                    fontFamily: "Oswald",
                    backgroundColor: "rgb(18 163 55)",
                    borderColor: "rgb(18 163 55)"
                }}
                onClick={openModal}
            >
                STOCKS
            </button>

            {/* React Modal */}
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                contentLabel="Available Stock"
                overlayClassName="modal-overlay"
                style={{
                    content: {
                        maxWidth: '80%',
                        maxHeight: '90vh',
                        margin: 'auto',  // This ensures the modal is centered
                        padding: '20px',
                        borderRadius: '10px',
                        backgroundColor: '#fff',
                        overflow: 'auto', // To prevent double scrollbars
                    },
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.6)',  // Optional: dark overlay background
                        display: 'flex',
                        alignItems: 'center',  // This ensures the modal is vertically centered
                        justifyContent: 'center',  // This ensures the modal is horizontally centered
                    }
                }}
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Available Stock</h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={closeModal}
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body" >
                        {/* Search input */}
                        <input
                            type="text"
                            className="form-control mb-3"
                            placeholder="Search by Product or Sub Product"
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />

                        <div className="row">
                            {filteredStockData.map((item) => (
                                <div className="col-md-4" key={item.SUB_PRODUCT_ID}>
                                    <div className="card mb-3 shadow-sm">
                                        <div className="card-body">
                                            <h5 className="card-title">{item.SUB_PRODUCT_NAME}</h5>
                                            <p className="card-text">
                                                <strong>Product:</strong> {item.PRODUCT_NAME} <br />
                                                <strong>Available Stock:</strong> {item.AVAILABLE_STOCK} <br />
                                                <strong>Status:</strong>{" "}
                                                {item.SUB_PRODUCT_STATUS === "A" ? "Active" : "Inactive"}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={closeModal}
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleExport}
                        >
                            Download Excel
                        </button>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default StockModal;
