import axios from "axios";
import { getAuthHeader } from "../../utils";

export const v2_ledger_entry_report = async (payload, page, limit) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/ledger/entry/${page}/${limit}`;
    return axios.post(url, payload, getAuthHeader()).then((res) => {
        return res.data;
    })
}


export const v2_ledger_cashbook_report = async (payload, page, limit) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/ledger/cashbook/${page}/${limit}`;
    return axios.post(url, payload, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const v2_ledger_transporter_report = async (payload, page, limit) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/ledger/transporter/${page}/${limit}`;
    return axios.post(url, payload, getAuthHeader()).then((res) => {
        return res.data;
    })
}


